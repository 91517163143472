import http from "@/services/api.js";
export const clientsModule = {
  state: {
    openedRowIds: [],
    areAllRowsOpened: false,
    clients: {
      loading: false,
      items: [],
      totalPages: 0,
    },
    query: {
      page: 1,
      pageSize: 20,
      fromDate: "",

      toDate: "",
    },
  },

  actions: {
    async fetchClients({ state }) {
      state.areAllRowsOpened=false
      state.clients.loading = true;
      try {
        const queryParams = {
          page: state.query.page,
          pageSize: state.query.pageSize,
          fromDate: state.query.fromDate,
          toDate: state.query.toDate,
        };

        
        const response = await http("/Clients/get-filtered-clients/paged", {
          params: queryParams,
        });
        console.log("response fetch clients", response);
        if (response.status === 200) {
          state.clients.items = response.data.items;
          state.clients.totalPages = response.data.totalPages;
        }
      } catch (err) {
        console.log(err);
      } finally {
        state.clients.loading = false;
      }
    },

    toggleRow({ state }, { id, value }) {

      if (state.openedRowIds.includes(id)) {
        state.openedRowIds = state.openedRowIds.filter((rowId) => rowId !== id);
      } else {
        state.openedRowIds.push(id);
      }

    },
    toggleAllRows({ state, commit, getters }, value) {
      state.areAllRowsOpened = value;

      if (value) {
        state.openedRowIds = [...getters.allRowIds];
      } else {
        state.openedRowIds = [];
      }
    },

    changePage({ state, dispatch }, page) {
      state.query.page = page;
      dispatch("fetchClients");
    },

    changePerPage({ state, dispatch }, value) {
      console.log("value from changePerPage", value);
      state.query.pageSize = +value;
      dispatch("fetchClients");
    },

    setDate({ state, dispatch }, dates) {
      state.query.fromDate = dates?.start;
      state.query.toDate = dates?.end;
      dispatch("fetchClients");
    },

    resetDate({ state, dispatch }) {
      state.query.fromDate = "";
      state.query.toDate = "";
      dispatch("fetchClients");
    },
  },

  getters: {
    getAllClients(state) {
      return state.clients;
    },
    getAllClientsQuery(state) {
      return state.query;
    },
    isRowOpen: (state) => (id) => state.openedRowIds.includes(id),
    allRowIds: (state) => state.clients.items.map((client) => client.id),
    allRowsState: (state) => state.areAllRowsOpened,
  
 
  },
};
