<template>
  <div class="wrapper">
    <div class="front-password" v-if="!isTaken">
      <div class="front-password-camera">
        <video ref="video" autoplay playsinline></video>
        <canvas ref="canvas" style="display: none"></canvas>
        <div class="front-password-info">
          <p>Обратная сторона паспорта</p>
          <p>
            Сделайте фото обратной стороны паспорта. Паспорт должен помещаться в
            рамки
          </p>
        </div>
        <div @click="takePicture" class="circle-photo">
          <img src="../../assets/img/circle-photo.svg" alt="photo" />
        </div>
      </div>
    </div>
    <div v-else class="front-password-camera">
      <div class="front-titles">
        <p class="title">Обратная сторона паспорта</p>

        <p class="description">
          Пожалуйста, убедитесь, что текст разборчив, отчетливо виден, и
          заполнена вся рамка.
        </p>
      </div>
      <img :src="photo" alt="front-passport" class="front-passport" />

      <div class="front-buttons">
        <button class="request__item_blue-button" @click="openFace">
          Готово
        </button>
        <button class="request__item_white-button" @click="reOpenCamera">
          Сделать заново
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const cameraRef = ref();

const video = ref(null);
const canvas = ref(null);
const photo = ref(null);
const stream = ref(null);

const imageUrl = ref("");
const isTaken = ref(false);

const startCamera = async () => {
  if (stream.value) stopCamera();
  try {
    stream.value = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment", width: 224, height: 350 },
    });

    if (video.value) {
      video.value.srcObject = stream.value;
      stream.value.height = 224;
    }
  } catch (err) {
    console.error("Error accessing the camera: ", err);
  }
};

const takePicture = () => {
  if (video.value && canvas.value) {
    const context = canvas.value.getContext("2d");
    canvas.value.width = video.value.videoWidth;
    canvas.value.height = video.value.videoHeight;
    context.drawImage(
      video.value,
      0,
      0,
      canvas.value.width,
      canvas.value.height,
    );
    isTaken.value = true;
    photo.value = canvas.value.toDataURL("image/png");

    localStorage.setItem("backImage", photo.value);
  }
};

const reOpenCamera = async () => {
  isTaken.value = false;
  startCamera();
};

const openFace = () => {
  if (photo.value) {
    localStorage.setItem("isFaceOpen", true);
    router.push("/face-passport");
  }
};

const stopCamera = () => {
  if (stream?.value) {
    stream?.value?.getTracks().forEach((track) => track?.stop());
  }
};

onBeforeUnmount(() => {
  stopCamera();
});

onMounted(() => {
  const header = document?.getElementById("k-grid__header");
  const footer = document?.getElementById("k-grid__footer");

  header.style.display = "none";
  footer.style.display = "none";
  const isBackOpen = localStorage?.getItem("isBackOpen");
  if (!isBackOpen || isBackOpen === "false") {
    router.push("/kyc");
  }

  startCamera();
});
</script>

<style scoped>
.front-password {
  position: absolute;
  background: #222222;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 120px 20px 60px 20px;
}
.front-password-camera {
  width: 350px;
}

.front-password-info {
  margin: 64px 0 124px 0;
}
.title {
  font-size: 20px;
  line-height: 26px;

  font-weight: 700;
  margin-bottom: 12px;
}

.front-password-info p:last-child {
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-weight: 400;
  max-width: 350px;
}

.front-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 90px;
  gap: 10px;
}

.front-buttons button {
  background: white;
}
.circle-photo {
  display: flex;
  justify-content: center;
}
.title {
  margin: 12px 0 24px 0;
}

.wrapper {
  padding: 20px;
}

.front-passport {
  margin: 24px 0 150px 0;
  object-fit: cover;
}
</style>
