<template>
  <div class="date-filter">
    <button class="bordered-btn" @click="toggleDate(!isDateOpen)">
      {{ selectedRange }}

      <span @click.stop="resetDate" v-if="isDateSelected">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="#222"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
          /></svg
      ></span>
    </button>
    <DatePicker
      v-model.range="range"
      mode="date"
      v-if="isDateOpen"
      class="calendar"
      locale="ru"
      @update:modelValue="updateDate"
    />
  </div>
</template>

<script setup>
import { formatDate } from "@/services/formatDate";
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";
import { computed } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
const range = ref({
  start: new Date(),
  end: new Date(),
});
const isDateSelected = ref(false);
const store = useStore();
const emit = defineEmits(["updateDate", "resetDate"]);
const props = defineProps({
  selectedRange: {
    type: String,
  },
});
const resetDate = () => {
  range.value = null;
  emit("resetDate");
  isDateOpen.value = false;
  isDateSelected.value = false;
};

const isDateOpen = ref(false);
const toggleDate = (value) => {
  isDateOpen.value = value;
};

const updateDate = (event) => {
  emit("updateDate", {
    startDate: formatDate(event?.start),
    endDate: formatDate(event?.end),
  });
  isDateSelected.value = true;

  isDateOpen.value = false;
};
</script>

<style scoped>
:deep(.vc-title) {
  background: white;
}

:deep(.vc-header .vc-arrow) {
  background: white;
  border: 1px solid #222;
}


.bordered-btn span {
  border-radius: 8px;
}
.bordered-btn span:hover {
  background: #f6f8fd;
}

.date-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  position: relative;
  margin-bottom: 20px;
}

:deep(.vc-container) {
  position: absolute !important;
  top: 48px;
}

.date-filter button {
  text-align: center;
}

:deep(.vc-nav-item),
:deep(.vc-nav-title),
:deep(.vc-nav-arrow) {
  background: white;
}
</style>
