<template>
  <div class="auth-page">
    <div class="auth-page__form">
      <div class="x-card x-card--lg">
        <div class="x-card__header">
          <h1 class="x-card__title">Регистрация</h1>
        </div>
        <div class="x-card__body">
          <h2>
            Регистрация прошла успешно! Пожалуйста перейдите на свою электронную
            почту и следуйте инструкциям в полученном письме.
          </h2>
        </div>
        <!-- /.card__body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.auth-page__form -->
  </div>
  <!-- /.auth-page -->
</template>

<script>
export default {
  name: "RegistrationSuccess",
};
</script>

<style scoped></style>
