<template>
  <div class="select-section">
    <input
      v-if="showInput"
      type="number"
      class="page-input"
      placeholder="max: 200"
      :max="201"
      :min="1"
      @keyup.enter="selectCustom"
      v-model="customPerPage"
    />
    <div
      class="select"
      @click="toggleDropdown"
      :style="[{ width: `${dropdownWidth}px` }]"
    >
      <div class="select-toggle" @mouseenter="toggleMouse(true)" @mouseleave="toggleMouse(false)">
        <span>
          {{ selectedItem }}
        </span>
        <svg width="21" height="20" viewBox="0 0 21 20"  xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5 12.175L16.3875 7.28753C16.78 6.89503 17.4172 6.89785 17.8062 7.29381C18.1904 7.68483 18.1876 8.31242 17.8 8.70003L10.5 16L3.20003 8.70003C2.81241 8.31241 2.80964 7.68483 3.19381 7.29381C3.58281 6.89785 4.22003 6.89503 4.61253 7.28753L9.5 12.175L10.5 13L11.5 12.175Z" :fill="isHovered ? '#ffffff' :'#222'"/>
          </svg>
          
        <!-- <img src="../../../assets/img/caret-down.svg" alt="caret" /> -->
      </div>

      <transition name="fade">
        <ul
          v-if="isOpen"
          class="select-menu"
          :style="{ width: `${dropdownWidth}px` }"
        >
          <li
            v-for="item in items"
            :key="item.value"
            @click.stop="selectItem(item)"
            class="item-option"
          >
            <span>{{ item?.name }}</span>

            <svg
              v-if="selectedItem === item.name"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#222"
              class="bi bi-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"
              />
            </svg>
          </li>
          <!-- <li v-else-if="!items.length && !isDataLoading">
    Нет данных
  </li>
  <li v-else>Загрузка...</li> -->
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedItem: {
      type: String,
      required: true,
    },

    showInput: {
      type: Boolean,
      required: false,
      default: false,
    },

    items: {
      type: Array,
      required: true,
    },

    dropdownWidth: {
      type: Number,
      default: 200,
    },

    dropdownToggle: {
      type: Object,
      default: () => ({}),
    },

    dropdownStyle: {
      type: Object,
      default: () => ({}),
    },
    isDataLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      customPerPage: 1,
      isHovered:false
    };
  },
  methods: {
    toggleMouse(value){
      this.isHovered=value


      console.log('toggleMouse',this.isHovered)
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    selectCustom() {
      if (
        typeof this.customPerPage === "number" &&
        this.customPerPage >= 1 &&
        this.customPerPage <= 200
      ) {
        this.$emit("selectItem", { value: this.customPerPage });
      } else {
        return;
      }
    },

    selectItem(item) {
      this.$emit("selectItem", item);
      this.isOpen = !this.isOpen;
      if (item.name === this.selectedItem) {
        this.$emit("removeSelected", item);
      }
    },
  },

  mounted() {
    this.customPerPage = this.selectedItem;
  },
};
</script>

<style scoped>
.select-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-section {
  display: flex;
  gap: 14px;
  align-items: center;
}

.page-input {
  border-radius: 4px;
  padding: 4px 16px;
  border: 1px solid #838383;
  width: 120px;
  display: block;
}

.select {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 8px;
  transition: .4s ease;
}


.select:hover{
  background: #0047ab;
  color:white;
  transition: .2s ease;
}
.select-toggle {
  border: 1px solid #ccc;
  padding: 10px 16px;
  border-radius: 8px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.select-menu {
  position: absolute;
  top: 100%;
  margin: 5px auto 0 auto;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1000;
  left: 0;
  right: 0;
  list-style: none;
  padding-left: 0px;
}

.select-menu li {
  padding: 8px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-align: left;
  color: #222222;
  line-height: 18px;
  border-radius: 4px;
}

.select-menu li:hover {
  background-color: #e9e9e9;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
