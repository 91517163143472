import axios from "axios";
import store from "../store/index";
const instance = axios.create({
  baseURL: "https://api.trustex.kg",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export default instance;
console.log("store inside apis", store);
const user = localStorage.getItem("user");

let userToken;

if (user && user !== null) {
  userToken = JSON.parse(user)?.jwtToken;
} else {
  userToken = null;
}

export const authApi = axios.create({
  baseURL: "https://api.trustex.kg",
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
  withCredentials: true,
});

authApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch("auth/refreshToken", userToken);
    }
    if (error.response) {
      console.error("Response Error:", error.response.data);
    } else if (error.request) {
      console.error("Request Error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  },
);
