import http from "@/services/api.js";
import { formatDate } from "@/services/formatDate";
export const p2tApp = {
  state: {
    openeP2tdRowIds: [],
    areAllP2tRowsOpened: false,
    allP2tApplications: {
      items: [],
      loading: false,
      totalPages: 0,
    },
    query: {
      page: 1,
      pageSize: 20,
      fromDate: "",
      toDate: "",

    },
  },
  actions: {
    setRangeDate({ state, dispatch }, range) {
      state.query.fromDate = range.startDate;
      state.query.toDate = range.endDate;
      dispatch("getP2tApp");
    },

    async getP2tApp({ state }) {
      state.allP2tApplications.loading = true;
      state.areAllP2tRowsOpened=false
      const queryParams = {
        page: state.query.page,
        pageSize: state.query.pageSize,
        fromDate: state.query.fromDate,
        toDate: state.query.toDate,
      };
      try {
        const response = await http("/P2T/get-all-applications", {
          params: queryParams,
        });
        if (response.status === 200) {
          state.allP2tApplications.items = response.data?.message?.items;
          state.allP2tApplications.totalPages =
            response.data.message?.totalPages;
        }

        console.log("response get all p2t app", response);
      } catch (err) {
        console.log(err);
      } finally {
        state.allP2tApplications.loading = false;
      }
    },

    resetP2tDate({ state, dispatch }) {
      state.query.fromDate = "";
      state.query.toDate = "";
      dispatch("getP2tApp");
    },
    changeP2tPage({ state, dispatch }, page) {
      state.query.page = page;
      dispatch("getP2tApp");
    },
    changeP2tPerPage({ state, dispatch }, perPage) {
      state.query.pageSize = perPage;

      dispatch("getP2tApp");
    },


    toggleP2tRow({ state }, { id, value }) {

      if (state.openeP2tdRowIds.includes(id)) {
        state.openeP2tdRowIds = state.openeP2tdRowIds.filter((rowId) => rowId !== id);
      } else {
        state.openeP2tdRowIds.push(id);
      }


    },
    toggleP2tAllRows({ state, commit, getters }, value) {
      state.areAllP2tRowsOpened = value;


      if (value) {
        state.openeP2tdRowIds = [...getters.allP2tRowIds];
      } else {
        state.openeP2tdRowIds = [];
      }
    },
  },
  getters: {
    getAllP2tApp(state) {
      return state.allP2tApplications.items;
    },

    getDateRange(state) {
      if (state.query.fromDate && state.query.toDate) {
        return `${state.query.fromDate} - ${state.query.toDate}`;
      } else {
        return "Выберите дату";
      }
    },

    getP2tLoadingApps(state) {
      return state.allP2tApplications.loading;
    },
    getP2tTotalPages(state) {
      return state.allP2tApplications.totalPages;
    },

    getP2tCurrentQuery(state) {
      return state.query;
    },

    isP2tRowOpen: (state) => (id) => state.openeP2tdRowIds.includes(id),
    allP2tRowIds: (state) => state.allP2tApplications?.items.map((client) => client.orderInfoId),
    allP2tRowsState: (state) => state.areAllP2tRowsOpened,
  },
};
