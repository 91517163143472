<template>
  <div class="container">
    <div class="k-grid__inner-body clients-section">
      
      <div class="client-header">

        <h1>Платежи</h1>

        <div class="d-flex flex-row align-items-center">
          <DatePicker />

          <div class="toggler">
            <div v-if="!allRowsState">
              <button class="blue-btn mb-4" @click="toggleAllRows(true)">
                Открыть все
              </button>
            </div>
            <div v-else>
              <button class="blue-btn mb-4" @click="toggleAllRows(false)">
                Закрыть все
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="$store.getters.getAllClients.loading" class="centered-block">
        <div class="spinner-border text-primary" role="status"></div>
      </div>

      <div
        v-else-if="
          !$store.getters.getAllClients.loading &&
          $store.getters.getAllClients.items?.length
        "
      >
        <div class="all-clients">
          <AdminClientsItem
            v-for="client in $store.getters.getAllClients.items"
            :key="client.id"
            :client="client"
          />
        </div>

        <div class="bottom-section">
          <Pagination
            :total="$store.getters.getAllClients.totalPages"
            :currentActive="$store.getters.getAllClientsQuery.page"
            @changePage="changePage"
          />
          <Select
            :showInput="true"
            :dropdownWidth="90"
            :items="itemsOptions"
            :selectedItem="selectedPerPage"
            @selectItem="selectItem"
          />
          <div>
            <button class="blue-btn" @click="downloadExcel">
              <img src="../../assets/img/download.svg" alt="download" />
              Скачать файл
            </button>
          </div>
        </div>
      </div>

      <div v-else class="centered-block">Нет данных</div>
    </div>
  </div>
</template>

<script>
import AdminClientsItem from "@/components/Admin/Clients/Item.vue";
import Pagination from "@/components/Admin/Ui/Pagination.vue";
import Select from "@/components/Admin/Ui/Select.vue";
import DatePicker from "@/components/Admin/Ui/DatePicker.vue";
import * as XLSX from "xlsx";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Client",
  data() {
    return {
      selectedPerPage: "20",
    };
  },
  computed: {
    ...mapGetters(["allRowsState"]),
    itemsOptions() {
      return [
        { name: "15", value: "15" },
        { name: "20", value: "20" },
        { name: "30", value: "30" },
        { name: "40", value: "40" },
      ];
    },
  },
  methods: {
    ...mapActions(["toggleAllRows"]),

    selectItem(item) {
      this.selectedPerPage = item.value;

      this.$store.dispatch("changePerPage", item.value);
    },

    changePage(page) {
      this.$store.dispatch("changePage", page);
    },

    downloadExcel() {
      const data = this.$store.getters.getAllClients.items.map((item) => {
        return {
          Сумма: item.amount,
          Дата: item.date,
          Откуда: item.from,
          Id: item.id,
          Телефон: item.phone,
          Статус: item.status,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Платежи.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },

  mounted() {
    this.$store.dispatch("fetchClients");   
  },

  components: {
    AdminClientsItem,
    Pagination,
    Select,
    DatePicker,
  },
};
</script>

<style scoped>
.clients-section {
  background: white;
  padding: 24px 32px;
  border-radius: 10px;
}

.all-clients {
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

h1 {
  display: block;
  color: #282828;
  font-size: 40px;
  line-height: 52px;
}

.k-grid__inner-body {
  flex-direction: column;
}

.bottom-section {
  display: flex;
  gap: 20px;
}

.client-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.toggler {
  margin-left: 10px;
}
</style>
