<template>
  <div class="container">
    <div class="k-grid__inner-body">
      <div class="p2t-app-section">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <h1>Все заявки p2t</h1>

       <div class="d-flex align-items-center">
        <DateRange
        @updateDate="updateDate"
        :selectedRange="getDateRange"
        @resetDate="resetDate"

      />

      <div class="toggler">
        <div v-if="!allP2tRowsState">
          <button class="blue-btn mb-4" @click="toggleP2tAllRows(true)">
            Открыть все
          </button>
        </div>
        <div v-else>
          <button class="blue-btn mb-4" @click="toggleP2tAllRows(false)">
            Закрыть все
          </button>
        </div>
      </div>
       </div>
        </div>

        <div v-if="getP2tLoadingApps" class="centered-block">
          <div class="spinner-border text-primary" role="status"></div>
        </div>

        <div v-else-if="!getP2tLoadingApps && getAllP2tApp?.length">
          <div class="user-applications">
            <AdminP2tUserApplications
              v-for="p2tApp in getAllP2tApp"
              :key="p2tApp?.orderInfoId"
              :p2tApp="p2tApp"
            />
          </div>

          <div class="d-flex mt-5 justify-content-center">
            <Pagination
              :total="getP2tTotalPages"
              :currentActive="getP2tCurrentQuery.page"
              @changePage="changeP2tPage"
            />
            <Select
              :showInput="true"
              :dropdownWidth="90"
              :items="itemsOptions"
              :selectedItem="getP2tCurrentQuery.pageSize"
              @selectItem="selectItem"
            />
          </div>
        </div>

        <div v-else class="centered-block">Нет данных</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AdminP2tUserApplications from "../../components/Admin/P2tUserApp/Item.vue";
import DateRange from "@/components/Admin/Ui/DateRange.vue";
import Pagination from "@/components/Admin/Ui/Pagination.vue";
import Select from "@/components/Admin/Ui/Select.vue";

export default {
  components: { AdminP2tUserApplications, DateRange, Pagination, Select },
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      "getP2tApp",
      "setRangeDate",
      "resetP2tDate",
      "changeP2tPage",
      "changeP2tPerPage",
      "toggleP2tAllRows"
    ]),
    updateDate(range) {
      console.log("range", range);

      this.setRangeDate(range);
    },
    resetDate() {
      this.resetP2tDate();
    },
    selectItem(item) {
      this.changeP2tPerPage(item?.value);
      // this.selectedPerPage = item.value
      // this.$store.dispatch('changePerPage',item.value)
    },
  },
  computed: {
    ...mapGetters([
      "getAllP2tApp",
      "getDateRange",
      "getP2tLoadingApps",
      "getP2tTotalPages",
      "getP2tCurrentQuery",
      "allP2tRowsState"
    ]),
    itemsOptions() {
      return [
        { name: "15", value: "15" },
        { name: "20", value: "20" },
        { name: "30", value: "30" },
        { name: "40", value: "40" },
      ];
    },
  },
  mounted() {
    this.getP2tApp();
  },
};
</script>

<style scoped>

.p2t-app-section {
  background: #fff;
  padding: 24px 32px;
  border-radius: 8px;
  width: 100%;
}

.user-applications {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.toggler {
  margin-left: 10px;
}

</style>
