<template>
    <div class='client-row'>

    <p class='client-subrow'>

<div class='d-flex align-items-center'>       
    <div class="toggle-arrow closed-arrow" v-if="!getIsRowOpen"   @click="toggleP2tRow({id:p2tApp?.orderInfoId,value:faBatteryThreeQuarters})">
        <img class="arrows" src="../../../assets/img/arrow-down.svg" alt="down">
    </div>

    <div v-else class="toggle-arrow open-arrow" @click="toggleP2tRow({id:p2tApp?.orderInfoId,value:false})"> <img src="../../../assets/img/arrow-up.svg" alt="arrow-up" class="arrows">
    </div>

    <span class="client-label">Телефон</span></div>
        <span class="client-info">{{ p2tApp?.phone }}</span>
    </p>


<p class='client-subrow'>
    <span class="client-label">Криптовалюта</span>
    <span class="client-info">{{ p2tApp?.cryptoValuteType }}</span>
</p>

       
<div class="row-between">
    <p class='client-subrow'>
        <span class="client-label">Сумма</span>
        <span class="client-info">{{ p2tApp?.totalAmount }}</span>
    </p>

   
</div>

<transition name="fade">
   <div v-if="getIsRowOpen" class="toggle-row">

    <p class='client-subrow'>
        <span class="client-label">Дата</span>
        <span class="client-info">{{ formatDate(p2tApp?.createdAt)  || 'Нет данных'}}</span>
    </p>

    <p class='client-subrow'>
        <span class="client-label">Телеграм</span>
        <span class="client-info">{{ p2tApp?.telegram  || 'Нет данных'}}</span>

    </p>
    <p class='client-subrow'>
        <span class="client-label">KYC</span>
       <span v-if="p2tApp?.kyc">
        <span class="client-info status-success" >
            <img src="../../../assets/img/checked-white.svg" alt="success">
            
        <span>
            Есть
        </span></span>
       </span>

       <span v-else>
        <!-- <span class="client-info status-success" >
            <img src="../../../assets/img/checked-white.svg" alt="success">
            
        <span> -->
            Нет

       </span>
    </p>
        <div class="row-between">

            <p class='client-subrow'>
                <span class="client-label">Тип покупки</span>
                <span class="client-info">{{ p2tApp?.typeOrder }}</span>
            </p>

          
         </div>

        </div>    
 

   </transition>

    </div>
</template>

<script>
import { faBatteryThreeQuarters } from '@fortawesome/free-solid-svg-icons';
import { mapActions } from 'vuex';

export default {
   name:'AdminUserP2tApplications',
   data(){
    return{
        isRowOpen:false
    }
   },
   props:{
    p2tApp:{}
   },

   methods:{
    ...mapActions(['toggleP2tRow']),
    formatDate(date) {
    const dateObj = new Date(date);
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); 
    const year = dateObj.getUTCFullYear();
    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    const formattedDate = `${day}.${month}.${year}, ${hours}:${minutes}`;
    return formattedDate;
      }
   },


   computed:{
    getIsRowOpen(){
        return this.$store.getters.isP2tRowOpen(this.p2tApp?.orderInfoId) 
    },
   }
}

</script>

<style scoped>
p{
    margin:0
}
.status-success{
background: #64D580;
padding:6px 16px;
border-radius: 8px;
display: flex;
gap:7px;
align-items: center;
}
.status-success span{
    color:white;
}

.client-row{
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 310px;
    background: #F6F8FD;
    border-radius: 10px;
    height:100%;
    padding:16px 24px 10px 24px;

}
.client-subrow{
    display: flex;
    flex-direction: column;
gap:4px;
margin-bottom: 10px;
}

  
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.client-info{
    color:#282828;
font-size: 16px;
line-height: 24px;
font-weight: 400;
}
.client-label{
    font-size: 14px;
    line-height: 20px;
    color:#28282890;
}


.arrows:hover{
    cursor: pointer;
}

.toggle-row{width: 100%;}
</style>