<template>
  <div class="buttons-pgn">
    <input
      type="number"
      class="page-input"
      v-model="inputPage"
      @keyup.enter="setPage"
    />
    <button
      class="each-button-pag"
      :disabled="currentPage === 1"
      @click="previousPage"
      :class="{ 'disabled-btn': currentPage === 1 }"

      @mouseenter="mouseHover(true)"
      @mouseleave="mouseHover(false)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        :fill="!isMouseHovered? '#222222':'#fff'"
        class="bi bi-arrow-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
        />
      </svg>
    </button>
    <span
      v-if="ellipseShowFirst"
      class="each-button-pag"

      @click="changePage(1)"
      >{{ 1 }}</span
    >
    <span v-if="ellipseShowFirst" class="ellipse">...</span>

    <ul class="list">
      <li
        v-for="page in visiblePages"
        :key="page"
        class="each-button-pag"
        :class="`${currentPage === page ? 'active-page' : ''}`"
        @click="changePage(page)"
      >
        {{ page }}
      </li>
    </ul>

    <span v-if="ellipseShow" class="ellipse1">...</span>
    <span
      v-if="ellipseShow"
      class="each-button-pag"
      @click="changePage(totalPages.length)"
      >{{ totalPages.length }}</span
    >

    <button
      class="each-button-pag"
      :disabled="currentPage === totalPages.length"
      @click="nextPage"
      :class="{ 'disabled-btn': currentPage === totalPages.length }"

         @mouseenter="mouseHoverNext(true)"
      @mouseleave="mouseHoverNext(false)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
         :fill="!isMouseHoveredNext? '#222222':'#fff'"
        class="bi bi-arrow-right"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      required: true,
    },
    currentActive: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentPage: this.currentActive,
      buttonsToShow: 3,
      inputPage: 1,
      isMouseHovered:false,
      isMouseHoveredNext:false
    };
  },
  computed: {
    totalPages() {
      let result = [];
      for (let i = 1; i <= this.total; i++) {
        result.push(i);
      }
      return result;
    },
    ellipseShowFirst() {
      return this.currentPage > 2;
    },
    visiblePages() {
      const currentPageIndex = this.totalPages.indexOf(this.currentPage);
      let start = currentPageIndex - Math.floor(this.buttonsToShow / 2);
      start = Math.max(start, 0);
      const end = start + this.buttonsToShow;
      return this.totalPages.slice(start, end);
    },
    ellipseShow() {
      return (
        this.visiblePages[this.visiblePages.length - 1] < this.totalPages.length
      );
    },
  },
  watch: {
    currentActive(newVal) {
      this.currentPage = newVal;
    },
  },
  methods: {
    mouseHover(value){
      this.isMouseHovered=value

    },
    mouseHoverNext(value){
      this.isMouseHoveredNext=value

    },
    changePage(newPage) {
      this.currentPage = newPage;
      this.$emit("changePage", newPage);
      this.inputPage = newPage;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.inputPage = this.currentPage;
        this.$emit("changePage", this.currentPage);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages.length) {
        this.currentPage++;
        this.inputPage = this.currentPage;
        this.$emit("changePage", this.currentPage);
      }
    },
    setPage() {
      if (
        typeof this.inputPage === "number" &&
        this.inputPage > 0 &&
        this.inputPage <= this.$store.getters.getAllClients.totalPages
      ) {
        this.changePage(this.inputPage);
      } else {
        return;
      }
    },
  },
  mounted() {
    this.inputPage = this.currentActive;
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.page-input {
  border-radius: 4px;
  padding: 4px 16px;
  border: 1px solid #838383;
  width: 20%;
}
.each-button-pag {
  border: 1px solid #222222;
  padding: 4px 10px;
  display: block;

  list-style: none !important;
  background: #f5f5f5;

  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  position: static;
  font-size: 14px;
  line-height: 20px;
  transition:.3s ease;
}
.each-button-pag:hover {
  cursor: pointer;
  background: #0047ab;
  transition:.2s ease;
  color:white;
}
.each-button-pag a {
  text-decoration: none;
  color: blue;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

li:hover {
  cursor: pointer;
}
.list {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 11px;
}

.buttons-pgn {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 11px;
}
.active-page {
  background: #0047ab;
  color: white;
}
.list li,
.text,
caption {
  margin: 0 !important;
}
</style>
